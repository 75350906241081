<template>
    <div>
        <input
            ref="input"
            v-model="searchValue"
            class="py-4 px-8 rounded-full w-130 shadow-xl font-light outline-none z-40"
            type="text"
            :placeholder="$t('common.start_typing') + '...'"
            @keyup="startedTyping"
            @keyup.esc="$emit('close')"
        >
        <fa-icon
            class="absolute right-0 mt-4 mr-3 text-2xl text-red-400 transition"
            :class="[ $wait.is('searching.*') ? 'opacity-100': 'opacity-0' ]"
            :icon="['fad', 'spinner-third']"
            spin
            fixed-width
        />
        <fa-icon
            class="absolute right-0 mt-4 mr-3 text-2xl text-red-400 transition cursor-pointer"
            :class="[ !$wait.is('searching.*') && resetInputField ? 'opacity-100': 'opacity-0' ]"
            :icon="['fal', 'times']"
            fixed-width
            @click="resetSearchResults"
        />
    </div>
</template>
<script>
export default {
    props: {
        resetInputField: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            searchValue: '',
        };
    },

    created() {
        this.$nextTick(() => this.$refs.input.focus());
    },

    methods: {
        startedTyping() {
            if (this.searchValue === '') {
                this.resetSearchResults();
                return;
            }
            this.$emit('searchValueChanged', this.searchValue);
        },

        resetSearchResults() {
            this.searchValue = '';
            this.$emit('resetSearchResults');
            this.$wait.end('searching');
        },
    },
};
</script>
